import styled, { ThemeProvider } from "styled-components";
import My_Header from "../../list/My_Header";
import { Outlet } from "react-router-dom";
import theme from "../../../styles/ThemeProps";

const Wrapper = styled.div` 
    position: relative;
    width: 100%;
    height: 100vh;
    /* background-color: #F4F9FC; */
    background-color: white;
    overflow: hidden;
`

export const ContentBox = styled.div`
    width: calc(100% - 180px);
    height: calc(100% - 90px);
    margin: 90px 0px 180px 180px;    
    @media ${( {theme} ) => theme.device.mobile_big } {
        width: calc(100% - 90px);
        height: calc(100% - 90px);
        margin: 90px 0px 0px 90px;
    }

    @media ${( {theme} ) => theme.device.mobile_small } {
        width: calc(100% - 90px);
        height: calc(100% - 90px);
        margin: 90px 0px 0px 90px;
    }  
    
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;

    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
`

const Layout = () => {
    return (
        <ThemeProvider theme={theme}>
            <Wrapper>
                <My_Header />
                <ContentBox>
                    <Outlet />
                </ContentBox>
            </Wrapper>
        </ThemeProvider>
    )
}

export default Layout;