import Axios from "./AxiosInterceptor";

/**
 * 회원가입 API 요청 type for post
 * @param {*} props 닉네임, 이메일 JWT화한 값, password 
 * @param {*} callback 
 */
export const requestSignUp = async(props, callback) => {
  const userInfo = props;
  let value = await Axios.post('/api/signUp', userInfo).then(req => {
    console.log('회원가입api')
    console.log(req.data)
    return req.data
  })

  callback(value);
}

/**
 * 로그인 상태 확인 api 요청, type for get
 * @param {*} callback 
 */
export const loginState = async(callback) => {
  let value = await Axios.get('/api/loginCheck').then((req) => {
    console.log('로그인정보 확인', req);
    if (req.data == true) {
      return true
    } else {
      return false;
    }
  })
  console.log(value);
  
  callback(value);
}

/**
 * 로그아웃 api 요청, type for get
 * @param {*} callback 
 */
export const logoutState = async(callback) => {
    const value = await Axios.get('/api/logout').then((req) => {
      if (req.data == true) {
        return false;
      } else {
        return true;
      }
    });

    callback(value);
}

/**
 * 유저정보 api 요청, type for post
 * @param {*} callback 
 */
export const requestUserInfo = async(callback) => {
  let value = await Axios.post('/accessCheck/userInfo').then((req) => {
    if (req.data == false) {
      return false
    } else {
      console.log('유저정보를 받아왔습니다.', req.data)
      return req.data[0]
    }
  }).catch((error) => {
    if (error.response.status === 401) {
      return '/login'
    }
    return false;
  })

  callback(value);
}

/**
 * 유저정보 api 요청, type for get
 * @param {*} callback 
 */
export const requestLetterInfo = async(callback) => {
    const value = await Axios.get('/api/letterinfo').then((req, error) => {
      return (req.data);
    })

    callback(value);
}

/**
 * 보낸편지 리스트 정보 요청 api, type for post
 * @param {*} callback 
 */
export const requestSendLetterInfo = async(callback) => {
  const value = await Axios.post('/accessCheck/sendletterinfo').then((req, error) => {
    return (req.data);
  })

  callback(value);
}

/**
 * 받은편지 리스트 정보 요청 api, type for post
 * @param {*} props lt_idx: string
 * @param {*} callback 
 */
export const requestReceiveLetterInfo = async(props, callback) => {
  const letterInfo = props;
  const value = await Axios.post('/accessCheck/receiveletterinfo', letterInfo).then((req, error) => {
    return (req.data);
  })

  callback(value);
}

/**
 * 내가 보낸 답장 리스트 요청 api, type POST
 * @param {*} callback 
 */
export const request_other_user_send_LetterInfo = async(callback) => {
  const value = await Axios.post('/accessCheck/sendToOtherUser').then((req, error) => {
    return (req.data);
  }).catch((error) => {
    if (error.response.data) {
      return false;
    }
  })

  callback(value);
}

/**
 * 카테고리 정보 요청 api, type for GET 
 * (미사용) 
 * @param {*} callback 
 */
export const requestCategoryInfo = async(callback) => {
  const value = await Axios.get('/accessCheck/CategoryInfo').then((req, error) => {
    return (req.data);
  }).catch((error) => {
    if (error.response.data){
      return false
    }
  })

  callback(value);
}

/**
 * 편지 작성을 요청합니다. 
 * @param {*} props content: 편지 내용, category: 유저의 category정보
 * @param {*} callback 콜백
 */
export const requestSendLetter = async(props, callback) => {
  const letterInfo = props;
  const value = await Axios.post('/accessCheck/postLetter', letterInfo).then((req, error) => {
    return (req.data);
  }).catch((error) => {
    if (error.response.data){
      return false
    }
  })

  callback(value);
}

/**
 * 편지 업데이트 api 요청, type for POST
 * @param {*} props local_mb_idx: 유저_idx, lt_idx: 편지_idx, content: 새 편지 내용
 * @param {*} callback 
 */
export const requestUpdateLetter = async(props, callback) => {
  const letterInfo = props;
  const value = await Axios.post('/accessCheck/updateLetter', letterInfo).then((req, error) => {
    return (req.data);
  }).catch((error) => {
    if (error.response.data){
      return false
    }
  })

  callback(value);
}

/**
 * 답장 api 요청, type for POST
 * @param {*} props 
 * @param {*} callback 
 * @see 답장과 맞답장 모두 한개의 테이블에 저장됨
 */
export const requestReceiveSender = async(props, callback) => {
  const letterInfo = props;
  const value = await Axios.post('/accessCheck/ReceiveSender', letterInfo).then((req, error) => {
    return (req.data);
  }).catch((error) => {
    if (error.response.data){
      return false
    }
  })

  callback(value);
}

/**
 * 편지프로젝트 참가 api 요청, type for POST
 * @param {*} callback 
 */
export const requestRegisterProject = async(callback) => {
  const value = await Axios.post('/accessCheck/RegisterProject').then((req, error) => {
    return (req.data);
  }).catch((error) => {
    if (error.response.data) {
      return false;
    }
  })

  callback(value);
}


/**
 * 편지 삭제 api 요청, type for POST
 * @param {*} props 
 * @param {*} callback 
 */
export const requestDeleteLetter = async(props, callback) => {
  const info = props;
  const value = await Axios.post('/accessCheck/deleteLetter', info).then((req, error) => {
    return (req.data);
  }).catch((error) => {
    if (error.response.data) { 
      return false;
    }
  })

  callback(value);
}


/**
 * 회원탈퇴 요청 api, type for POST
 * @param {} callback 
 */
export const requestDeleteUser = async(callback) => {
  const value = await Axios.post('/accessCheck/deleteUser').then((req, error) => {
    return (req.data);
  }).catch((error) => {
    if (error.response.data) { 
      return false;
    }
  })

  console.log(value)
  callback(value);
}

export const requestUpdateCategory = async(props, callback) => {
  const info = props;
  console.log(info)
  const value = await Axios.post('/accessCheck/updateCategory', info).then((req, error) => {
    return (req.data);
  }).catch((error) => {
    if (error.response.data){
      return false
    }
  })

  callback(value);
}

/**
 * 읽이않은 편지 리스트 api 요청, type for POST
 * @param {*} callback 
 */
export const requestUnreadLetterCheck = async(callback) => {
  const value = await Axios.post('/accessCheck/unreadLetterCheck').then((req, error) => {
    console.log(req.data)
    return (req.data);
  }).catch((error) => {
    if (error.response.data) {
      return false
    }
  })

  callback(value);
}

/**
 * 답장 읽음 여부 갱신 api 요청, type for POST
 * @param {*} props 
 * @param {*} callback 
 */
export const requestUpdateReceiverRead = async(props, callback) => {
    const info = props;
    const value = await Axios.post('/accessCheck/updateReceiverRead', info).then((req, error) => {
      return (req.data);
    }).catch((error) => {
      if (error.response.data) {
        return false
      }
    })
    callback(value);
}

/**
 * 닉네임 업데이트 api 요청
 * @param {*} props mb_nick: 변경할 닉네임
 * @param {*} callback 
 */
export const requestUpdateNick = async(props, callback) => {
  const info = props;
  console.log(info)
  const value = await Axios.post('/accessCheck/updateNick', info).then((req, error) => {
    return (req.data);
  }).catch((error) => {
    if (error.response.data){
      return false
    }
  })

  callback(value);
}