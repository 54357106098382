import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Content, Line_span, Category, ReceiveCnt, Keywords, SendButton,
         SetupBox, SetupButton, SetBox, SetButton, ReadBlur } from "./LetterContent";
import { slideIn } from "../../ui/Animation";
import { useState } from "react";
import { requestDeleteLetter, requestUpdateReceiverRead } from "../../../Controller/Controller";


const pastelColor = ['#BA8F6B','#AD8A6A','#D0AB80','#F9ECCF','#C69966','#F1CEA3']

const Wrapper = styled.div`
    width: calc(25% - 8px);     //* 반응형, 화면에 항상 3개의 편지가 보이도록
    height: 472px;
    margin: 0px calc(3.666% - 1px) 90px;

    @media ${({ theme }) => theme.device.computer } {
        width: 375px;     
        height: 472px;
        margin: 0px calc(3.666% - 1px) 60px;  
    }
    
    @media ${({ theme }) => theme.device.laptop } {
        width: calc(45%);     
        height: 472px;
        margin: 30px 0px 60px;
    }

    @media ${({ theme }) => theme.device.tablet } {
        width: calc(45%);
        height: 472px;
        margin: 30px 0% 60px;
    }

    @media ${({ theme }) => theme.device.mobile_big } {
        width: calc(100% - 32px);
        height: 472px;
        margin: 30px 0% 60px;        
    }

    @media ${({ theme }) => theme.device.mobile_small } {
        width: calc(100% - 32px);
        height: 472px;
        margin: 30px 0% 60px;  
    }

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 472px;
    animation: ${() => slideIn} 0.5s ease-in-out;
`;

const randomColor = () => {
    let index = Math.floor(Math.random() * pastelColor.length)
    return pastelColor[index];
}

const replaceKeywords = (keywords) => {
    if (keywords) { // keywords가 0일 때 예외처리
        const reKeywords = keywords.replace(' ', '');
        const newKeywords = '#' + reKeywords.replace(',', '#');
        return newKeywords;
    }

    return ''
}

const LetterBox = (props) => {
    // * content: 'From. nick'포함, realContent: 미포함
    const { content, realContent, category, receive_lt_cnt, keywords, mb_idx, mb_nick, lt_idx, write_sorted, return_sorted } = props;
    const { root_lt_idx, sender_mb_idx, read_yn, ex_lt_idx} = props;
    const [setting, setSetting] = useState(false);
    var spanArr = [];
    const sliceContent = (!content) ? '' : content.split('\n');
    const bgColor = randomColor();
    const navigation = useNavigate()

    const handleSetupButton = (e) => {
        setSetting(!setting);
    }

    const makeSpan = (sliceContent, return_sorted) => {
        const arr = []
    
        for(let i = 0; i < 5; i++) {
            if (i === 0 && (write_sorted || return_sorted)) {
                console.log(return_sorted)
                arr.push(<SetupBox>
                    <Line_span dp='inline' key={i}>{sliceContent[i] ? sliceContent[i] : ' '}</Line_span>
                    <SetupButton onClick={handleSetupButton}/>
                </SetupBox>)
            } else {
                arr.push(<Line_span dp='block' key={i}>{sliceContent[i] ? sliceContent[i] : ' '}</Line_span>)
            }
        }
    
        return arr;
    }

    const handleEdit = (e) => {
        const info = { mb_idx: mb_idx, lt_idx: lt_idx, content: realContent, category: category, write: true };

        navigation('/write', { state: info })
    }

    const handleDelete = (e) => {
        if (return_sorted) {
            // 받은편지 삭제
            const info = { user_lt_idx: root_lt_idx, user_mb_idx: sender_mb_idx }    
            requestDeleteLetter(info, data => {
                if (data) window.location.reload();
                else alert('서버와 통신중 오류가 발생하였습니다. 관리자에게 문의해주세요 hek33kr@gmail.com')
            })
        } else {
            // 내가 쓴 편지 삭제
            const info = { user_lt_idx: lt_idx }
            requestDeleteLetter(info, data => {
                if (data) window.location.reload();
                else alert('서버와 통신중 오류가 발생하였습니다. 관리자에게 문의해주세요 hek33kr@gmail.com')
            })
        }
        
    }

    const handleSendButton = (e) => {
        if (write_sorted) {
            navigation('/myPage/receiver', { state: { lt_idx: lt_idx }});
        } else if (return_sorted) {
            if (read_yn) {
                const lt_info = {lt_idx: root_lt_idx}
                requestUpdateReceiverRead(lt_info, data => {
                    if (data) {
                        const info = { mb_idx: sender_mb_idx, mb_nick: mb_nick, content: content, ex_lt_idx: ex_lt_idx }
                        console.log(info)
                        navigation('/read', { state: info })
                    }
                })
            } else {
                const info = { mb_idx: sender_mb_idx, mb_nick: mb_nick, lt_idx: root_lt_idx, content: content, ex_lt_idx: ex_lt_idx }
                console.log(info)
                navigation('/read', { state: info })
            }
        } else {
            const info = { mb_idx: mb_idx, mb_nick: mb_nick, lt_idx: lt_idx, content: content, ex_lt_idx: ex_lt_idx }
            console.log(info)
            navigation('/read', { state: info })
        }
    }

    spanArr = makeSpan(sliceContent, return_sorted);

    return (
        <Wrapper>
            <Content bgColor={bgColor}>

                {read_yn ? <ReadBlur/> : <></>}

                {setting && ( return_sorted 
                ? 
                    <SetBox>
                        <SetButton onClick={handleDelete}>삭제</SetButton>
                    </SetBox>
                :
                    <SetBox>
                        <SetButton onClick={handleEdit}>수정/확인</SetButton>
                        <SetButton onClick={handleDelete}>삭제</SetButton>
                    </SetBox>
                )}
                {spanArr}
            </Content>
            {category && (
                <Category>
                    about {category}
                </Category>
            )}
            {return_sorted ? (
                <>
                </>
            ) : (
                <ReceiveCnt>
                    {receive_lt_cnt ? `답장 ${receive_lt_cnt}개` : '답장 0개'}
                </ReceiveCnt>
            )}
            <SendButton onClick={handleSendButton}>{write_sorted ? '답장 확인' : '편지 확인'}</SendButton>
        </Wrapper>
    )
}

export default LetterBox;