import Header from "../../list/Header";
import { Outlet } from "react-router-dom";
import Footer from "../../list/Footer";

const Layout = () => {
    return (
        <>
            <Header />
            <Outlet />
        </>
    )
}

export default Layout;