import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../styles/ThemeProps';

const Wrapper = styled.div`
    top: 66.719px;
    position: relative;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #c2c2c2;
    background-color: Teal;

    > * {
        /* :last-child {
            margin: 16px 180px 32px 180px;
        } */
    }
`

const ContentBox = styled.div`
    @media ${({ theme }) => theme.device.computer} {
        margin: 16px 180px;
    }
    
    @media ${({ theme }) => theme.device.laptop} {
        margin: 16px 180px;
    }
    
    @media ${({ theme }) => theme.device.tablet} {
        margin: 16px;
    }

    @media ${({ theme }) => theme.device.mobile_big} {
        margin: 16px;
    }
    
    @media ${({ theme }) => theme.device.mobile_small} {
        margin: 16px;
    }

    position: relative;
    display: inline;

`

const LinkList = styled.ul`
    list-style: none;
`

const LinkContent = styled.li`
    font-size: 11px;
    margin: 0 0 0 8px;
    float: left;
    color: white;
`

const SmallContent = styled.span`
    position: relative;
    display: inline-block;
    font-size: 11px;
    padding: 0 0 8px 0;
    color: white;
`

const Line = styled.div`
    display: inline-block;
    width: 1px;
    height: 10px;
    margin: 0 10px 7px;
    opacity: .4;
    background-color: #c2c2c2;
    vertical-align: top;
`

function Footer() {
    const hostUrl = window.location.hostname;
    const afref = {
        text_decoration_line: "none",
        color: "white",
    }

    return (
        <ThemeProvider theme={theme}>
            <Wrapper>
                <ContentBox>
                    <LinkList>
                        <LinkContent><a style={afref} href={'http://' + hostUrl + ':8428' + '/conditions'}>이용약관</a></LinkContent>
                        <LinkContent><a style={afref} href={'http://' + hostUrl + ':8428' + '/privacy'}>개인정보처리방침</a></LinkContent>
                    </LinkList>
                </ContentBox>
                <ContentBox>
                    <SmallContent>나의하루(주)</SmallContent>
                    <Line/>
                    <SmallContent>대표이사: 김정훈</SmallContent>
                    <Line/>
                    <SmallContent>소재지: 충청남도 아산시 배방읍 광장로 210, A동 1층 125호</SmallContent><br/>
                    <SmallContent>사업자등록번호 : 177-87-02522</SmallContent>
                </ContentBox>
            </Wrapper>
        </ThemeProvider>
        

    // <footer>
    //   <div>
    //     <h3>회사명</h3>
    //     <p>주소: 서울시 강남구</p>
    //     <p>전화번호: 123-456-7890</p>
    //     <p>Email: info@example.com</p>
    //   </div>
    // </footer>
    );
}

export default Footer;