import styled from "styled-components";
import LetterList from "../../list/Letter/LetterList";

const Sender_LT_Page = (props) => {
    const {} = props;

    return ( 
            <LetterList receive_sorted={true} write_sorted={true} return_sorted={false}/>
    )
}

export default Sender_LT_Page;