import React, { useState } from 'react'
import styled from 'styled-components'

const CheckboxContainer = styled.label`
  display: inline-flex;
  align-items: center;
  vertical-align: middle;

  align-self: stretch;
`;

const StyledCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

const CheckboxWrapper = styled.span`
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #839EC3;
  border-radius: 8px;
`;

const CheckCicle = styled.span`
    position: absolute;
    
    top: 3px;
    left: 3px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${props => (props.checked ? 'skyblue' : 'transparent')};
`

const CheckboxLabel = styled.span`
    position: relative;
    font-size: 12px;
    margin-left: 8px;

    text-align: center;
`;

const CheckBox = (props) => { 
    // text, 체크버튼의 내용 
    // openPage, 클릭했을때 이동할 페이지
    // onChange, 체크버튼 클릭시 동작하는 핸들러
    const { checked, text, openPage, changeHandle } = props;

    return (
        <CheckboxContainer >
            <StyledCheckbox type="checkbox"  onChange={changeHandle} />
            <CheckboxWrapper > 
                <CheckCicle checked ={checked}/>
            </CheckboxWrapper>
            <CheckboxLabel>{text}</CheckboxLabel>
        </CheckboxContainer>
    )
}

export default CheckBox;
