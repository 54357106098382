import styled from "styled-components";

var concern = ['친구/연인','선배/후배/동기','상사/선임','썸/짝사랑','시험/자격증', '경제/제태크','취업/창업','사회생활',
               '자기개발','취미/여가','헬스/필라테스','운동/스포츠','관람/스포츠','연극/공연','책/드라마/영화','여행/캠핑'];               
var personality = ['활발한','침착한','낮가림','친근한','꼼꼼한','털털한','엉뚱한','소심한','현실적인','이상적인','배려심',
                   '직설적','순수한','계획적','즉흥적','외향적','내향적'];

const ConcernTitleLabel = styled.label`
    margin: 32px;
    font-size: 24px;
    @media ${( {theme} ) => theme.device.mobile_big } {
        margin: 16px;
        font-size: 18px;
    }

    @media ${( {theme} ) => theme.device.mobile_small } {
        margin: 16px;
        font-size: 18px;
    }  
    
    position: relative;
    font-family: GyeonggiBatang;
    
    
    :after {
        content: '* 최대 2개';
        font-size: 12px;
        vertical-align: middle;
    }
`

const ConcernChoiceBox = styled.div`
    width: 500px;
    font-size: 14px;
    height: auto;
    @media ${( {theme} ) => theme.device.mobile_big } {
        width: 100%;
        font-size: 10px;
    }

    @media ${( {theme} ) => theme.device.mobile_small } {
        width: 100%;
        font-size: 10px;
    }  

    position: relative;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
`

const ConcernButton = styled.button`
    position: relative;
    width: 20%;
    margin: 8px 1%;
    padding: 4px 0px;
    font-size: 1em;

    border: 1px solid skyblue;
    overflow: hidden;
    border-radius: 16px;

    background-color: ${props => props.name == 'true' ? 'skyblue' : 'transparent'};

    transform: background-color 0.25s ease-in-out;
`

const ConcernList = (props) => {
    const { handleConcern, handlePersonal } = props;
    const { userCategoryList } = props;
    const { pushConcern, pushpersonal } = props;
    const concernButtonList = [];
    const personalButtonList = [];

    concern.forEach((item, index) => {
        var state = 'false';
        if (userCategoryList) {
            userCategoryList.forEach(item2 => {
                if (item == item2) {
                    state = 'true'
                    pushConcern(item2)
                }
            })
        }
        concernButtonList.push(<ConcernButton key={index} name={state} value={item} onClick={handleConcern}>{item}</ConcernButton>)
    })
    
    personality.forEach((item, index) => {
        var state = 'false';
        if (userCategoryList) {
            userCategoryList.forEach(item2 => {
                if (item == item2) {
                    state = 'true'
                    pushpersonal(item2)
                }
            })
        }
        personalButtonList.push(<ConcernButton key={index} name={state} value={item} onClick={handlePersonal}>{item}</ConcernButton>)
    })

    return (
        <>
            <ConcernTitleLabel>나의 관심사/고민은? </ConcernTitleLabel>
            <ConcernChoiceBox>
                {concernButtonList}
            </ConcernChoiceBox>
            <ConcernTitleLabel>나의 성격은? </ConcernTitleLabel>
            <ConcernChoiceBox>
                {personalButtonList}
            </ConcernChoiceBox>
        </>
    )
}

export default ConcernList;