import { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../../styles/ThemeProps';
import Menu from './Header/HeaderMenu';
import HeaderAccount from './Header/HeaderAccount';
import logo from "../../resource/logo-blue-vertical.png";
import MenuIcon from '@mui/icons-material/Menu';
import { useRef } from 'react';
import MobileMenu from './Header/MobileMenu';

const Wrapper = styled.div`
    @media ${({ theme }) => theme.device.computer } {
        width: calc(100% - 180px);
        padding: 0px 90px;    
    }
    
    @media ${({ theme }) => theme.device.laptop } {
        width: calc(100% - 90px);
        padding: 0px 45px;    
    }

    @media ${({ theme }) => theme.device.tablet } {
        width: calc(100% - 60px);
        padding: 0px 30px;    
    }

    @media ${({ theme }) => theme.device.mobile_big } {
        width: calc(100%);
        padding: 0px 16px 0 0;    
    }

    @media ${({ theme }) => theme.device.mobile_small } {
        width: calc(100%);
        padding: 0px 16px 0 0;
    }

    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    isolation: isolate;

    height: 66.719px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    
    z-index: 100;
`

const Header_Box = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 6px 20px;
    width: 100%;
    height: 54.719px;
`

const LogoBox = styled.div`
    position: relative;
    height: 100%;
    flex-grow: 1;

    @media ${({ theme }) => theme.device.mobile_big } {
        flex-grow: 0;
    }

    @media ${({ theme }) => theme.device.mobile_small } {
        flex-grow: 0;
    }
`

const LogoImg = styled.img`
    position: relative;
    height: 100%;
    object-fit: contain;
`

const MenuBox = styled.div`
    position: relative;
    align-self: center;
    cursor: pointer;
`

const Header = (props) => {
    const [clientWidth, setClientWidth] = useState(window.innerWidth);
    const [menu, setMenu] = useState(false);
    const menuRef = useRef();
    
    const handleResize = (e) => {
        setClientWidth(e.target.innerWidth);
        setMenu(false);
    }

    const handleMenuBox = (e) => {
        setMenu(!menu);    
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        
        //컴포넌트가 마운트 시 이벤트 리스너 제거
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    return (
        <ThemeProvider theme={theme}>
            { clientWidth < 768 
            ? 
                <Wrapper>
                    <Header_Box>
                        <LogoBox>
                            <LogoImg src={logo}/>
                        </LogoBox>
                        <MenuBox ref={menuRef} onClick={handleMenuBox}>
                            <MenuIcon />
                        </MenuBox>
                        {menu && <MobileMenu menutop={menuRef.current.offsetTop} menuleft={menuRef.current.offsetLeft} menuwidth={menuRef.current.offsetWidth}/>}
                    </Header_Box>
                </Wrapper> 
            :
                <Wrapper>
                    <Header_Box>
                        <LogoBox>
                            <LogoImg src={logo}/>
                        </LogoBox>
                        <Menu/>
                        <HeaderAccount />
                    </Header_Box>
                </Wrapper>
            }
        </ThemeProvider>
    )
}

export default Header