import styled from "styled-components";
import LinkToCategory from "../list/linkToCategory";

const Wrapper = styled.div`
    top: 66.719px;
    width: 100vw;
    height: auto;
    
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: white;
`

const LTProjectPage = (props) => {
    return ( 
        <Wrapper>
            <LinkToCategory />
        </Wrapper>
    )
}

export default LTProjectPage;