import styled, { ThemeProvider } from "styled-components";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom"
import { Title, CategoryItem } from "./LTProject/LinkingCategory";
import { requestCategoryInfo, requestRegisterProject, requestUserInfo } from "../../Controller/Controller";
import testImg from '../../resource/contents/image_4.png';
import projectImg from '../../resource/project-img.png';
import CheckBox from "../ui/CheckBox";
import theme from "../../styles/ThemeProps";

// 카테고리 정보를 담기위함
var info;

const Wrapper = styled.div`
    width: 80%;
    

    @media ${({ theme }) => theme.device.computer } {
        width: 80%;
        padding: 0 10%;
    }
    
    @media ${({ theme }) => theme.device.laptop } {
        width: 80%;
        padding: 0 10%;
    }

    @media ${({ theme }) => theme.device.tablet } {
        width: 100%;
        padding: 0;
    }

    @media ${({ theme }) => theme.device.mobile_big } {
        width: 100%;
        padding: 0;
    }

    @media ${({ theme }) => theme.device.mobile_small } {
        width: 100%;
        padding: 0;
    }

    position: relative;

    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    background-color: white;
`

const Participation = styled.img`
    @media ${({ theme }) => theme.device.computer } {
        width: 100%;
    }
    
    @media ${({ theme }) => theme.device.laptop } {
        width: 100%;
    }

    @media ${({ theme }) => theme.device.tablet } {
        width: 140%;
    }

    @media ${({ theme }) => theme.device.mobile_big } {
        width: 140%;
    }

    @media ${({ theme }) => theme.device.mobile_small } {
        width: 140%;
    }
    
    position: relative;
    border-color: none;

    object-fit: contain;
    cursor: pointer;
`

const ContentBox = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: auto;

    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Descryption = styled.div`
    margin: 3% 0;
    font-size: 18px;
    @media ${({ theme }) => theme.device.computer } {
        width: 50%;
    }
    
    @media ${({ theme }) => theme.device.laptop } {
        width: 50%;
    }

    @media ${({ theme }) => theme.device.tablet } {
        width: 80%;
        font-size: 14px;
    }

    @media ${({ theme }) => theme.device.mobile_big } {
        width: 80%;
        font-size: 11px;
    }

    @media ${({ theme }) => theme.device.mobile_small } {
        width: 80%;
        font-size: 11px;
    }
    
    position: relative;
    display: flex;
    
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Descript = styled.span`
    position: relative;
    max-width: 80%;
    margin: 16px 0;
    
    font-family: GyeonggiBatang;
    font-size: 1em;
    white-space: pre-wrap;
`

const CheckBoxalignMidlleBox = styled.div`
    position: relative;
    margin: 16px 0;
`

const LtProjectButton = styled.button`
    position: relative;
    display: block;
    width: 256px;
    height: 36px;
    padding: 1px 2px;
    border: 1px solid skyblue;
    border-radius: 8px;
    color: skyblue;
    text-align: center;
    font-family: var(--fnt);
`;

const calcItemWidth = () => {
    const width = window.innerWidth / 100 * 22.5;
    return width;
}

const calcItemHeight = () => {
    const height = calcItemWidth() * 1.8;
    return height
}

const LinkToCategory = (props) => {
    const [state, setState] = useState(2);
    const [click, setClick] = useState(false);
    const [agree, setAgree] = useState(false);
    const [itemWidth, setItemWidth] = useState(calcItemWidth);
    const [itemHeight, setItemHeight] = useState(calcItemHeight);
    const wrapRef = useRef();
    const navigation = useNavigate();

    const handleLtProject = (e) => {
        // 편지프로젝트 신청 핸들
        if (agree) {
            requestRegisterProject((data) => {
                if (data) {
                    alert('편지프로젝트에 정상적으로 등록되었습니다.')
                    window.location = '/'
                } else {
                    alert('서버 오류가 발생하였습니다.')
                    window.location = '/'
                }
            })
        } else {
            alert('아래 내용을 모두 확인해주세요!')
        }
        
    }

    const handleCheck = (e) => {
        console.log(agree);
        setAgree(!agree);
    }

    //onClick이벤트 사용하여 신청페이지로 넘어가야함
    const handleParticipation = (e) => {
        setClick(true);
    }

    const handleResize = (e) => {
        console.log('줄어드는중')
        setItemWidth(calcItemWidth);
        setItemHeight(calcItemHeight);
    }

    useEffect(() => {
        
        window.addEventListener('resize', handleResize);
        
        requestUserInfo((data) => {

            if (data === '/login') {
                navigation(data)
            }

            console.log(data)
            if (data.status === 1 ) {
                info = data
                setState(data.status)
            } else {
                info = data
                setState(data.status)
            }
            
        })

        //컴포넌트가 마운트 시 이벤트 리스너 제거
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <Wrapper ref={wrapRef}>
                {click ? 
                    <>
                        <Title>다음내용을 꼭 확인해주세요</Title>
                        <ContentBox>
                            <Descryption>
                                <Descript>1. 편지프로젝트는 서로의 고민과 관심사에 대한 이야기를 편지로 전달하며 이야기할 수 있도록 합니다.</Descript>
                                <Descript>2. 최초 설정한 관심사에 맞는 이야기를 편지로 작성하고 읽을 수 있습니다. (관심사는 '내공간-설정'에서 변경 가능합니다) </Descript>
                                <Descript>3. 당신의 편지를 설렘을 안고 기다리는 사람들이 있습니다. 편지를 통해 서로의 이야기를 전달해보세요. </Descript>
                                {/* <Descript>4. 현재 서비스는 베타 버전입니다. 지속적으로 업데이트 될 예정이며, 추후 앱 서비스를 통해 정식 오픈예정입니다. </Descript> */}
                            </Descryption>
                            <CheckBoxalignMidlleBox>
                                <CheckBox checked={agree} text="위 내용을 모두 확인하였습니다." changeHandle={handleCheck}/>
                            </CheckBoxalignMidlleBox>
                            <LtProjectButton onClick={handleLtProject}>시작하기</LtProjectButton>
                        </ContentBox>
                    </>
                :
                    state === 1 ?
                        <>
                            <Title>설정한 관심사를 주제로 편지를 확인해보세요</Title>
                            <CategoryItem title={'같은 성향'} img={testImg} width={itemWidth} height={itemHeight}
                                    onClick={() => navigation('category', { state: { title: '같은 성향', category: info.category, letterSetCount: 1 }})}/>
                            <CategoryItem title={'비슷한 성향'} img={testImg} width={itemWidth} height={itemHeight}
                                    onClick={() => navigation('category', { state: { title: '비슷한 성향', category: info.category, letterSetCount: 2 }})}/>
                            <CategoryItem 
                                    title={'다른 성향'} img={testImg} width={itemWidth} height={itemHeight}
                                    onClick={() => navigation('category', { state: { title: '다른 성향', category: info.category, letterSetCount: 3 }})}/>
                        </>
                    :
                        
                        state === 0 ? 
                            <>
                                <Participation src={projectImg} width={(wrapRef.current.clientWidth * 8/10) * 9/16} onClick={handleParticipation}/>
                            </>
                        
                        :
                            (<></>)
                }
            </Wrapper>
        </ThemeProvider>
        
    )
}

export default LinkToCategory;