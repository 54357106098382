import styled from "styled-components";
import { slideIn } from "../../ui/Animation";

export const Title = styled.h3`
    width: 100%;
    margin: 32px 0px;   

    position: relative;
    justify-items: center;

    @media ${({ theme }) => theme.device.computer } {
        font-size: 36px;
    }
    
    @media ${({ theme }) => theme.device.laptop } {
        font-size: 36px;
    }

    @media ${({ theme }) => theme.device.tablet } {
        font-size: 24px;
    }

    @media ${({ theme }) => theme.device.mobile_big } {
        font-size: 16px;
    }

    @media ${({ theme }) => theme.device.mobile_small } {
        font-size: 16px;
    }

    font-Family: GyeonggiBatang-bold;
    text-align: center;
    color: gray;

    animation: ${() => slideIn} 0.5s ease-in-out;
`

export const CategoryItem = styled.div`
    background-image: ${(props) => (props.img && `url(${props.img})`)};
    background-repeat: no-repeat;
    background-size: ${props => `calc(${props.width}px)`} ${props => `calc(${props.height}px)`};
    transition: backdrop-filter 1s ease;

    width: ${props => `calc(${props.width}px)`};
    height: ${props => `calc(${props.height}px)`};
    
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    animation: ${() => slideIn} 0.5s ease-in-out;
    

    ::after {
        content: "${(props) => props.title}";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        font-family: GyeonggiBatang;
        text-align: center;
        vertical-align: center;
        font-weight: 500;
        font-size: 24px;

        @media ${({ theme }) => theme.device.computer } {
            font-size: 24px;
        }
        
        @media ${({ theme }) => theme.device.laptop } {
            font-size: 24px;
        }

        @media ${({ theme }) => theme.device.tablet } {
            font-size: 16px;
        }

        @media ${({ theme }) => theme.device.mobile_big } {
            font-size: 11px;
        }

        @media ${({ theme }) => theme.device.mobile_small } {
            font-size: 11px;
        }

        color: white;
        line-height: ${props => `calc(${(props.height)}px)`};

        background-color: rgba(255,255,192,0.1);
        backdrop-filter: blur(10px);
        transition: all 0.15s ease-in-out;
    }

    &:hover::after {
        backdrop-filter: blur(0px);
    }
`