import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AxiosHttpData } from "../../Controller/Axios.config";
import logoblue from "../../resource/logo-blue.png";

// var email, password = ' ';               // Email, Password를 저장하기 위한 전역변수값

const Wrapper = styled.form`
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    
    > * {
        margin-bottom: 8px;
    }
`;

const Logo = styled.img`
    position: relative;
    object-fit: cover;
    width: 250px;
    height: 250px;
`;

const LogoFrame = styled.div`
    position: relative;
    width: 250px;
    height: 250px;
    z-index: 100;
`;

const EmailInput = styled.input`
    position: relative;
    width: 250px;
    height: 36px;
    border: 1px solid black;
    border-radius: 8px;

    ::placeholder {
        color: gray;
    }
`;

const PasswordInput = styled(EmailInput)`

`;

const LoginButton = styled.button`
    position: relative;
    display: block;
    width: 256px;
    height: 36px;
    padding: 1px 2px;
    border: 1px solid skyblue;
    border-radius: 8px;
    color: skyblue;
    text-align: center;
    font-family: var(--fnt);
`;

const SignButton = styled(LoginButton)`
    background-color: skyblue;
    color: white;
`

const LoginFalseMessage = styled.span`
    position: relative;
    width: 250px;
    height: 20px; 
    font-family: var(--fnt);
    color: red;
    text-align: center;
`;

const LoginPage = (props) => {
    const [email, setEmail] = useState(' ');
    const [password, setPassword] = useState(' ');
    const [errMessage, setErrMessage] = useState('');
    const navigation = useNavigate();

    const handleChangeEmail = (e) => {
        setEmail(e.target.value);
    }

    const handleChangePassword = (e) => {
        setPassword(e.target.value);
    }

    const handleLogin = (e) => {
        e.preventDefault();
        console.log(email, password);
        const userInfo = { 'email': email, 'password': password};
        const loginRequest = axios.create(AxiosHttpData);

        if (!email) {
            setErrMessage('이메일 형식을 확인해주세요');
        } else if (!email.includes('@')) {
            setErrMessage('이메일 형식을 확인해주세요');
        } else if(password.length == 0) {
            setErrMessage('비밀번호를 입력해주세요')
        } else {
            loginRequest.post('/api/login', userInfo).then((req) => {       // 로그인 실패 혹은 성공 로직
                if (req.data) {
                    console.log(req.data)
                    navigation(-1);
                } else {
                    setErrMessage('이메일, 혹은 비밀번호를 확인해주세요');
                }
            })
        }
    }

    const handleLogo = (e) => {
        e.preventDefault();
        navigation('/')
    }

    const handleSign = (e) => {
        e.preventDefault();
        navigation('/sign')
    }

    return (
        <Wrapper onSubmit={handleLogin}>
            <LogoFrame onClick={handleLogo}> 
                <Logo src={logoblue}/>
            </LogoFrame>
            <EmailInput placeholder="이메일을 입력해주세요" type='email' onChange={handleChangeEmail}/>
            <PasswordInput placeholder="비밀번호를 입력해주세요" type='password' onChange={handleChangePassword}/>
            <LoginButton onClick={handleLogin}>로그인</LoginButton>
            <SignButton onClick={handleSign}>회원가입</SignButton>
            <LoginFalseMessage>{errMessage}</LoginFalseMessage>
        </Wrapper>
    )
}

export default LoginPage;