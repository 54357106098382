import styled, { keyframes } from "styled-components";
import { useNavigate } from 'react-router-dom';
import { loginState, requestUnreadLetterCheck } from "../../../Controller/Controller";
import { useEffect } from "react";
import { useState } from "react";

const Wrapper = styled.div`
    position: relative;
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-color: none;
    text-align: center;
    vertical-align: middle;

    max-width: calc(33.333%);
`;

const Menu_name = styled.button`
    min-width: 60px;

    position: relative;
    font: var(--fnt);
    padding: 0px;
`;

const bumbling = keyframes`
    to {
        opacity: 0;
    }
    from {
        opacity: 1;
    }
`

const Read_Noti = styled.div`
    top: -6px;
    right: -6px;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    position: absolute;

    background-color: red;
    opacity: 0;

    animation: ${() => bumbling} 1.0s linear infinite alternate;
`

const Menu = (props) => {
    const navigation = useNavigate()
    const [state, setState] = useState(false)

    useEffect(() => {
        requestUnreadLetterCheck(data => {
            if (data) setState(true);
        })
    }, [])

    const handleCheckloginState = (path) => {
        loginState((state) => {
            if (state) {
                navigation(path)
            } else {
                navigation('/login')
            }
        })
    }

    return (
        <Wrapper>
            <Menu_name onClick={() => {navigation('/')}}>홈</Menu_name>
            <Menu_name onClick={() => handleCheckloginState('/letter')}>편지 프로젝트</Menu_name>
            <Menu_name onClick={() => handleCheckloginState('/myPage')}>
            {state ? <Read_Noti/> : <></>}
            내 공간
            </Menu_name>
        </Wrapper>
    )
}

export default Menu;