import styled from "styled-components";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { loginState, logoutState } from "../../../Controller/Controller";

const Wrapper = styled.div`
    top: ${props => `calc(66.719px)`};
    /* left: ${props => `calc(2px * ${props.menuleft} - ${window.innerWidth}px)`}; */
    right: 0px;
    /* width: ${props => `calc((${window.innerWidth} - ${props.menuleft})px * 2)`}; */
    
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;

    border-color: none;
    background-color: white;
`;

const Menu_name = styled.button`
    font: var(--fnt);
    margin: 16px;
`;


const MobileMenu = (props) => {
    const { menutop, menuleft, menuwidth } = props;
    const [login, setLogin] = useState(false);
    const navigation = useNavigate();
    
    const handleCheckloginState = (path) => {
        loginState((state) => {
            if (state) {
                navigation(path)
            } else {
                navigation('/login')
            }
        })
    }

    const logout_action = () => {
        setLogin(logoutState())
        navigation('/');
    }

    loginState((value) => {
        console.log(value);
        setLogin(value);
    })

    return (
        <Wrapper menutop={menutop} menuleft={menuleft} menuwidth={menuwidth}>
            <Menu_name onClick={() => {navigation('/')}}>홈</Menu_name>
            <Menu_name onClick={() => handleCheckloginState('/letter')}>편지 프로젝트</Menu_name>
            <Menu_name onClick={() => handleCheckloginState('/myPage')}>내 공간</Menu_name>
            {login ? (
                    <></>            
                ) : (
                    <Menu_name id="header_login_mypage" title="로그인" onClick={() => navigation('/login')}>로그인</Menu_name>
                )
            }
            {login ? ( 
                    <Menu_name id="header_login_mypage" title="로그아웃" onClick={() => logout_action()}>로그아웃</Menu_name>
                ) : 
                (
                    <Menu_name id="header_login_mypage" title="회원가입" onClick={() =>  navigation('/sign')}>회원가입</Menu_name>
                )
            }
        </Wrapper>
    )
}

export default MobileMenu;