import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { logoutState, requestDeleteUser, requestUpdateCategory, requestUserInfo, requestUpdateNick } from "../../../Controller/Controller";
import ConcernList from "../../list/ConcernList";

var concernValue = [];
var personalValue = [];
var before_nick

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
`

const ChangeConcernButton = styled.button`
    position: relative;

    width: 256px;
    height: 36px;
    padding: 1px 2px;
    border: 1px solid skyblue;
    border-radius: 8px;
    color: skyblue;
    text-align: center;
    font-family: var(--fnt);
    margin: 32px;
`

const Nickname = styled.input`
    position: relative;

    width: 256px;
    height: 36px;
    padding: 1px 2px;
    border: 1px solid black ;
    border-radius: 8px;
    text-align: center;
    font-family: var(--fnt);
    margin: 16px;
`

const NickTitle = styled.span`
    margin-top: 32px;
    position: relative;
    font-family: GyeonggiBatang;
`

const Signout = styled.button`
    position: relative;
    align-self: end;
    margin-right: 32px;
`

const SettingPage = (props) => {
    const [categoryList, setCategoryList] = useState([]);
    const [nick, setNick] = useState('');
    const navigation = useNavigate(); 

    // 유저정보의 카테고리 데이터를 배열에 저장하는데 사용됩니다.
    const pushConcern = (data) => {
        concernValue.push(data);
    }

    const pushpersonal = (data) => {
        personalValue.push(data);
    }

    // 관심사, 성격 중복제거
    const duplication_delete_list = () => {
        const newConcern = new Set(concernValue)
        concernValue = [...newConcern]
        const newPersonal = new Set(personalValue)
        personalValue = [...newPersonal]
    }
    // ConcernList 컨트롤 핸들
    const handleConcernButton = (e) => {
        duplication_delete_list()
        console.log(e.target.name)
        if (e.target.name == 'false') {
            console.log('선택확인')
            // 선택확인
            if (concernValue.length < 2) {
                // 선택한 수가 2개보다 적을 때
                concernValue.push(e.target.value);    
                e.target.name = 'true';
                e.target.style.backgroundColor = "skyblue";
            }
            
        } else {
            console.log('선택취소')
            // 선택취소
            const copyConcernValue = concernValue;
            concernValue = copyConcernValue.filter((item) => item !== e.target.value);
            
            console.log('삭제된 데이터', concernValue);
            e.target.name = 'false';
            e.target.style.backgroundColor = "transparent";
        }
    }
    // ConcernList 컨트롤 핸들
    const handlePersonalButton = (e) => {
        duplication_delete_list()
        if (e.target.name == 'false') {
            // 선택확인
            if (personalValue.length < 2) {
                // 선택한 수가 2개보다 적을 때
                personalValue.push(e.target.value);    
                e.target.name = 'true';
                e.target.style.backgroundColor = "skyblue";
            }
            
        } else {
            // 선택취소
            const copyPersonalValue = personalValue;
            personalValue = copyPersonalValue.filter((item) => item !== e.target.value);
            e.target.name = 'false';
            e.target.style.backgroundColor = "transparent";
        }
    }
    // ConcernList 컨트롤 핸들
    const handleChangeConcern = (e) => {
        duplication_delete_list()

        // 카테고리 변경 API 호출
        concernValue = concernValue.sort();
        personalValue = personalValue.sort();
        var categoryArr = [...concernValue, ...personalValue]
        const category = categoryArr.join(','); 
        const info = { category: category }
        

        console.log(categoryList, concernValue, personalValue)
        if (categoryList != categoryArr) {
            requestUpdateCategory(info, (data) => {
                if (data) {
                    alert('관심사를 변경했습니다')
                } else {
                    alert('서버와 통신중 에러가 발생했습니다 관리자에게 문의 바랍니다')
                }
            })
        }
        
        console.log(nick, before_nick)
        if (nick !== before_nick) {
            const newNick = {mb_nick: nick, before_nick: before_nick};
            requestUpdateNick(newNick, data => {
                if (data) {
                    alert('닉네임을 변경했습니다.')
                } else {
                    alert('중복된 닉네임 입니다 다른 닉네임을 입력해주세요')
                }
            })
        }
    }
    //회원탈퇴 핸들
    const handleSignOut = (e) => {
        // 회원탈퇴 API 호출

        if (window.confirm('내가쓴 편지, 주고받은 편지, 지금까지 보냈던 모든 편지가 사라져요 \n정말 탈퇴하시겠어요?')) {
            requestDeleteUser((data) => {
                if (data) {
                    console.log('회원가입후 로그아웃 진행')
                    logoutState((data)=>{
                        console.log('로그아웃 진행됨', data)
                        if (!data) {
                            
                            navigation('/')
                        }
                    })
                }
            })
        }
    }

    // 기존에 선택한 선택지가 무엇인지 먼저 확인할 수 있어야함
    useEffect(() => {
        requestUserInfo(data => {
            const userCategory = data.category || '';
            const userCategoryList = userCategory.split(',');
            before_nick = data.mb_nick;
            setCategoryList(userCategoryList);
            setNick(data.mb_nick);
        })
    }, [])

    return (
        <Wrapper>
            <ConcernList userCategoryList={categoryList} handleConcern={handleConcernButton} handlePersonal={handlePersonalButton} pushConcern={pushConcern} pushpersonal={pushpersonal}/>
            <NickTitle>닉네임</NickTitle>
            <Nickname type="text" placeholder={nick} onChange={(e) => setNick(e.target.value)}></Nickname>
            <ChangeConcernButton onClick={handleChangeConcern}>변경하기</ChangeConcernButton>
            <Signout onClick={handleSignOut}>회원탈퇴</Signout>
        </Wrapper>
    )
}

export default SettingPage