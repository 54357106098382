import { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { transitionGroup, CSSTransition } from "react-transition-group"
import CategoryLetterPage from "./component/page/CategoryLetterPage";
import HomePage from "./component/page/HomePage";
import Layout from "./component/page/layout/Layout";
import NoFooterLayout from "./component/page/layout/NoFooterLayout";
import LoginPage from "./component/page/LoginPage";
import LTProjectPage from "./component/page/LtProjectPage";
import SignPage from "./component/page/SignPage";
import My_Layout from "./component/page/my_page/My_Layout";
import Sender_LT_Page from "./component/page/my_page/Sender_LT_Page";
import Receiver_LT_Page from "./component/page/my_page/Receiver_LT_Page";
import WritePage from "./component/page/my_page/WritePage";
import SettingPage from "./component/page/my_page/SettingPage";
import ReadPage from "./component/page/ReadPage";
import ServicePage from "./component/page/etc/ServicePage";
import PrivacyPage from "./component/page/etc/PrivacyPage";
import RcLetterList from "./component/list/Letter/rcLetterList";

function App() {
  var location = window;
  var mobile = (/iphone|ipad|ipod|android/i.test(navigator.userAgent.toLowerCase()));
  const ua = navigator.userAgent.toLowerCase();

  useEffect(() => {
    // * 모바일과 PC를 구분하여 앱 서비스로 이동하도록 합니다.
    if (ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1 || ua.indexOf("ipod") > -1) {              // *아이폰일경우
      location.href = "https://apps.apple.com/kr/app/id1639296945";
      window.open(location.href);
      window.location.assign(location.href);
    } else if (ua.indexOf("android") > -1) {        // 안드로이드일경우
      location.href = "https://play.google.com/store/apps/details?id=kr.sharenshare.apps.myday&pli=1";
      window.open(location.href);
      window.location.assign(location.href);
    }
  }, []);

  if (mobile) {
    return ( <></> );
  }

  return (
    /**
     * 추후 하위 페이지로 구분할 필요가 있습니다. 
     * ex) diary 하위 write 페이지 
     */
    <BrowserRouter >
      <Routes >
        {/* Header, Footer 등의 레이아웃 적용 */}
        <Route element={<Layout />} >
          <Route index element={<HomePage/>}/>
        </Route>

        {/* Header 레이아웃 적용 */}
        <Route element={<NoFooterLayout />} >
          <Route index element={<HomePage/>}/>
          <Route path='/letter/*'> 
            <Route index element={<LTProjectPage />} />
            <Route path='category' element={<CategoryLetterPage />} />
          </Route>
        </Route>

        {/* My_Header, Footer 등의 레이아웃 적용 */}
        <Route path='/myPage/*'>
          <Route element={<My_Layout />}>
            <Route index element={<Sender_LT_Page />} />
            <Route path="receiver" element={<Receiver_LT_Page />} />        
            <Route path="setting" element={<SettingPage />}/>
            <Route path="toletter" element={<RcLetterList />} />  
          </Route>
        </Route>

        {/* Header, Footer 등의 레이아웃 미적용 */}
        <Route path='/login' element={<LoginPage />} />
        <Route path='/sign' element={<SignPage />} />
        <Route path="/write" element={<WritePage/>}/>
        <Route path="/read" element={<ReadPage/>} />
        <Route path="/conditions" element={<ServicePage/>} />
        <Route path='/privacy' element={<PrivacyPage/>} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
