import { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router";
import { loginState, logoutState } from "../../../Controller/Controller";


const Wrapper = styled.div`
    position: relative;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    max-width: calc(33.333%);

    text-align: center;
    vertical-align: middle;

    
`;

const Menu_name = styled.button`
    font: var(--fnt);
    padding: 0px;

    :nth-child(1) {
        margin-right: 50px;
    }
`;


const HeaderAccount = (props) => {
    const [login, setLogin] = useState(false);
    const navigation = useNavigate();

    const logout_action = () => {
        setLogin(logoutState())
        navigation('/');
    }

    loginState((value) => {
        console.log(value);
        setLogin(value);
    })

    return (
        <Wrapper>
            {login ? (
                    <></>            
                ) : (
                    <Menu_name id="header_login_mypage" title="로그인" onClick={() => navigation('/login')}>로그인</Menu_name>
                )
            }
            {login ? ( 
                    <Menu_name id="header_login_mypage" title="로그아웃" onClick={() => logout_action()}>로그아웃</Menu_name>
                ) : 
                (
                    <Menu_name id="header_login_mypage" title="회원가입" onClick={() =>  navigation('/sign')}>회원가입</Menu_name>
                )
            }
        </Wrapper>
    )
}

export default HeaderAccount;