import styled, { ThemeProvider } from "styled-components";
import { useNavigate } from "react-router-dom";
import { BtWrapper, ImgSource, BtTitle } from "./My_header/MyHeaderAccount";
import theme from "../../styles/ThemeProps";
import logo from "../../resource/logo-blue.png";
import send from "../../resource/mypage_resource/letter_send.png"
import receive from "../../resource/mypage_resource/letter-receive.png"
import write from "../../resource/mypage_resource/letter-write.png"
import setting from "../../resource/mypage_resource/setting-pink.png"
import home from "../../resource/mypage_resource/home.png"
import sender from "../../resource/mypage_resource/letter-sender.png"

const Wrapper = styled.div`
    height: calc(100% - 180px);
    margin: 90px 90px;

    @media ${( {theme} ) => theme.device.computer } {
        width: 180px;
    }

    @media ${( {theme} ) => theme.device.laptop } {
        width: 180px;
        margin: 90px 0px;
    }

    @media ${( {theme} ) => theme.device.tablet } {
        width: 180px;
        margin: 90px 0px;
    }

    @media ${( {theme} ) => theme.device.mobile_big } {
        width: 90px;
        margin: 90px 0px;
    }

    @media ${( {theme} ) => theme.device.mobile_small } {
        width: 90px;
        margin: 90px 0px;
    }    

    position: fixed ;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    

    border-radius: 32px;
    background-color: white;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25);

    z-index: 100;

    > * {

        :first-child {
            flex-grow: 3;
        }
    }
`

const My_Header = (props) => {
    const {} = props;
    const navigation = useNavigate();

    return (
        <ThemeProvider theme={theme}>
            <Wrapper>
                <BtWrapper>
                    <ImgSource src={logo}/>
                </BtWrapper>    
                <BtWrapper onClick={() => navigation('/myPage')}>
                    <ImgSource src={send}/>
                    <BtTitle>내가 쓴 편지 목록</BtTitle>
                </BtWrapper>
                <BtWrapper onClick={() => navigation('/myPage/receiver')}>
                    <ImgSource src={receive}/>
                    <BtTitle>내가 받은 답장</BtTitle>
                </BtWrapper>    
                <BtWrapper onClick={() => navigation('/myPage/toletter')}>
                    <ImgSource src={sender}/>
                    <BtTitle>답장 한 편지</BtTitle>
                </BtWrapper>    
                <BtWrapper onClick={() => navigation('/write', {state:{ mb_idx: '', lt_idx: '', content: '',  category: '인간관계', write: true}})}>
                    <ImgSource src={write}/>
                    <BtTitle>글쓰기</BtTitle>
                </BtWrapper>    
                <BtWrapper onClick={() => navigation('/myPage/setting')}>
                    <ImgSource src={setting}/>
                    <BtTitle>설정</BtTitle>
                </BtWrapper>    
                <BtWrapper onClick={() => navigation('/')}>
                    <ImgSource src={home}/>
                    <BtTitle>홈</BtTitle>
                </BtWrapper>    
            </Wrapper>
        </ThemeProvider>
    )
}

export default My_Header;