import styled from "styled-components";
import { useLocation } from "react-router-dom";
import LetterList from "../../list/Letter/LetterList";

const Receiver_LT_Page = (props) => {
    const {} = props;
    const { state } = useLocation();
    const { lt_idx } = state || {};

    return (
        <LetterList receive_sorted={true} return_sorted={true} lt_idx={lt_idx}/>
    )
}

export default Receiver_LT_Page;