import styled from "styled-components";
import setupImage from "../../../resource/mypage_resource/setting-black.png"


export const Content = styled.div`
    position: relative;
    font-family: GyeonggiBatang;
    font-weight: 500;
    font-size: 16px;
    line-height: 2.1;
    color: black;
    overflow-x: hidden;
    overflow-y: hidden;
    white-space: pre;
    
    background: ${(props) => (props.bgColor)};
    /* background: linear-gradient(45deg, transparent 7%, ${(props) => (props.bgColor)} 0, ${(props) => (props.bgColor)} 100%) left, linear-gradient(-135deg, transparent 7%, ${(props) => (props.bgColor)} 0, ${(props) => (props.bgColor)} 100%) right;  */
    
    
    width: calc(100%);
    height: auto;        //* 반응형
    margin: 16px;

    > * {
        left: 16px;
        right: 16px;
        margin-top: 12px;
        margin-bottom: 12px;
        :last-child {
            margin-bottom: 48px;
        }
    }

    ::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        width: 0;
        height: 0;
        border-top: 48px solid white;
        border-left: 48px solid transparent;
    }

    ::after {
        content: " ";
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 0;
        height: 0;
        border-bottom: 48px solid white;
        border-right: 48px solid transparent;
    }
`;

export const Line_span = styled.span`
    position: relative;
    display: block;
    font-family: GyeonggiBatang;
    font-weight: 500;
    font-size: 16px;
    color: black;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;

    max-width: 80%;

`;

export const Category = styled.div`
    font: var(--fnt);
    font-weight: 400;
    text-align: center;
    color: gray;
`;

export const ReceiveCnt = styled.div`
    font: var(--fnt);
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    color: skyblue;
`;

export const Keywords =  styled.div`
    font: var(--fnt);
    font-weight: 400;
    text-align:center;
    color: black;
`;

export const SendButton = styled.button`
    position: relative;
    width:  65%;
    height: 36px;
    border-radius: 18px;

    font-size: 24px;
    
    background-color: #5588B0;
    color: white;
`

export const SetupBox = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-start;
    width: auto;
    height: auto;
`

export const SetupButton = styled.button`
    position: relative;
    width: 32px;
    height: 32px;

    background-image: url('/resource/setting-black.png');
    background-repeat: no-repeat;
    background-size: cover;
`

export const SetBox = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 40px;
    left: 25%;
    width: 50%;
    height: 50%;

    background-color: white;
    z-index: 10;
`
export const SetButton = styled.button`
    position: relative;
    width: 100%;
    height: 50%;
    font-size: 16px;
    color: gray;
`

export const ReadBlur = styled.div`
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    margin: 0;
    position: absolute;

    ::after {
        content: ' ';
        background-color: rgba(255,255,192,0.1);
        backdrop-filter: blur(5px);

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    z-index: 101;
`