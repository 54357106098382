import styled from "styled-components"

export const BtWrapper = styled.button`
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 12px 0px;
    width: 82.22%;
    background-color: white;
    height: 10%;
`;

export const ImgSource = styled.img`
    position: relative;
    width: 100%;
    height: 70%;
    object-fit: contain;
`;

export const BtTitle = styled.span`
    font-size: 16px;
    @media ${( {theme} ) => theme.device.mobile_big } {
        font-size: 10px;
    }

    @media ${( {theme} ) => theme.device.mobile_small } {
        font-size: 10px;
    }  


    text-align: center;
    color: #c39b9a;
`;