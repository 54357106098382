import { useState, useEffect, useRef } from "react";
import styled, { keyframes, ThemeProvider } from "styled-components";
import autoTextarea from "react-textarea-autosize"
import { requestReceiveSender, requestSendLetter, requestUpdateLetter } from "../../../Controller/Controller"
import { useLocation, useNavigate } from "react-router-dom";
import theme from "../../../styles/ThemeProps";

const wording = ['지금, 가고싶은 곳이 있나요?', '오늘 하루는 어땠나요', '당신이 생각하는 완벽한 하루는 어떤 날인가요?', '하루를 색으로 비유한다면 어떤 색상일까요?', '어떤 일이 있었는데 특히 기억에 남는 순간이 있으셨나요?', '오늘 하루 동안 어떤 감정을 느꼈나요? 그 이유는 무엇인가요?', '가장 큰 성취감을 느낀 일은 무엇이었나요?','만난 사람 중에서 가장 인상깊은 사람이 있으셨나요? 그 이유는 무엇인가요?', '일상 속에서 가장 행복한 순간은 언제였나요?', '기억에 남는 영화나 드라마가 있나요?', '오늘 어떤 일이 아쉬웠거나 좋지 않았던 순간이 있으셨나요? 왜 그런 감정을 느꼈나요?', '오늘 하루를 색으로 비유한다면 어떤색상일까요?']

var beforeTextareaHeight = 36;
// var areaValue = '';

const randomWording = () => {
    let index = Math.floor(Math.random() * wording.length)
    return wording[index];
}

const Wrapper = styled.div`
    width: 50vw;
    margin: 0px 25vw;
    height: auto;
    
    @media ${( {theme} ) => theme.device.mobile_big } {
        width: 100vw;
        margin: 0px;
    }

    @media ${( {theme} ) => theme.device.mobile_small } {
        width: 100vw;
        margin: 0px;
    }  

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    overflow-y: scroll;
    overflow-x: hidden;

    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
`

const HeaderLine = styled.div`
    top: 32px;
    width: 100%;
    position: absolute;
    font-family: GyeonggiBatang-bold;
    font-size: 24px;

    @media ${( {theme} ) => theme.device.mobile_big } {
        font-size: 14px;
    }

    @media ${( {theme} ) => theme.device.mobile_small } {
        font-size: 14px;
    }  
    text-align: center;

    
`

const AreaBox = styled.div`
    width: 95%;
    min-height: ${() => `calc(${window.innerHeight}px - 48px)`};
    @media ${( {theme} ) => theme.device.mobile_big } {
        width: 100%;
    }

    @media ${( {theme} ) => theme.device.mobile_small } {
        width: 100%;
    }  

    position: relative;
    display: flex;

    justify-content: flex-start;
    background-color: #FCF5E6;
`

const Letter_Area = styled(autoTextarea)`
    position: relative;
    width: 84%;
    height: ${props => `calc(${props.rows} * 36px)`};
    margin: calc(1080px * 0.1) 8% calc(1080px * 0.1) 8%;
    padding: 0;
    font-size: 16px;
    line-height: 36px;
    overflow: hidden;

    border: none; 
    resize: none;
    background-color: #FCF5E6;

    outline: none;

    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
`

const lineDrow = keyframes`
    from {
        width: 0px;
    }

    to {
        width: 95%;
    }
`

const Letter_Line = styled.div`
    position: absolute;
    height: 1px;
    margin-top: calc(1080px * 0.1);
    margin-left: 2.5%;
    margin-right: 2.5%;
    top: ${(props) => (`calc((36px * ${props.top}))`)};

    background-color: gray;
    animation: ${() => lineDrow} 0.5s ease-out forwards;
    transition: all 0.5s ease-out forwards;

    z-index: 10;
`

const SendButton = styled.button`
    width: 95%;
    @media ${( {theme} ) => theme.device.mobile_big } {
        width: 100%;
    }

    @media ${( {theme} ) => theme.device.mobile_small } {
        width: 100%;
    }  
    position: relative;
    height: 48px;

    font-size: 16px;
    font-weight: bold;
    background-color: skyblue;
    color: white; 
`

const WritePage = (props) => {
    // textArea onChange에 resizeTextaarea이벤트를 등록한후 useEffect로 value값에 content를 넣어주면 자동으로 줄이 생성될 것이다.
    // category는 유저가 가진 카테고리 정보를 가져와서 작성하도록함
    // lt_idx: 있으면 답장, 없으면 편지 작성 이때 category를 사용함
    const { mb_idx, lt_idx, content, category, mb_nick, write, sender, receive_sender, ex_lt_idx } = useLocation().state;
    const [rows, setRows] = useState(1);
    const [areaValue, setAreaValue] = useState('');
    const [title, setTitle] = useState(randomWording());
    const [line_element, setLine_element] = useState([<Letter_Line top={1}/>]);
    const areaRef = useRef();
    const navigation = useNavigate(); 

    const resizeTextarea = (e) => {
        const { scrollHeight, clientHeight, value } = e.target;    
        const textareaHeight = 36;                           //한줄의 텍스트가 입력될 수 있는 Height값 (fontSize+line-Height)
        const rowValue = scrollHeight / textareaHeight       //클라이언트의 길이를 늘리기 위해 스크롤길이가 현재Height을 초과한 경우를 고려한 수
        const scrollToBottomHeight = document.documentElement.clientHeight / 2;

        setAreaValue(value);

        if (scrollHeight > clientHeight) {
            setLine_element([...line_element, <Letter_Line top={rowValue}/>])
            setRows(rowValue)
        }

        console.log(beforeTextareaHeight, ' ', clientHeight);
        if (beforeTextareaHeight > clientHeight) {
            setLine_element([...line_element.slice(0, rows-1)])
            setRows(rowValue)
        }
        
        // 글이 절반이상 넘어가면 스크롤이 항상 제일 밑으로 이동
        if (scrollHeight > scrollToBottomHeight) {
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'smooth'  
            })
        }

        beforeTextareaHeight = scrollHeight;                // 스크롤조정이 완료된 후 현재 스크롤 높이를 저장합니다.
    }

    const handleSendButton = (e) => {
        if (areaValue.length < 10) {
            alert('최소 10글자이상 입력해주세요')
        }
        const newAreaValue = areaValue.replace(/'/g, "\\'")

        if (write) {
            if (mb_idx !== '' && lt_idx !== '') {
                const info = {
                    local_mb_idx: mb_idx,
                    lt_idx: lt_idx,
                    content: newAreaValue,
                }
                requestUpdateLetter(info, data => {
                    if (data == true) {
                        navigation('/myPage');
                    }
                    console.log(data);
                })
            } else {
                const info = {
                    content: areaValue,
                    category: category,
                    keywords: '',
                }
                requestSendLetter(info, data => {
                    if (data == true) {
                        navigation('/myPage');
                    }
                    console.log(data);
                })
            }            
        } else if (sender) {
            // 답장
            const info = {
                receiver_mb_idx: mb_idx,
                root_lt_idx: lt_idx,
                content: newAreaValue,
                ex_lt_idx: ex_lt_idx
            }
            requestReceiveSender(info, data => {
                if (data == true) {
                    navigation('/myPage');
                }
                console.log(data);
            })
        } else if (receive_sender) {
            // 답장에 답장
            const info = {
                receiver_mb_idx: mb_idx,
                root_lt_idx: lt_idx,
                content: newAreaValue,
                ex_lt_idx: ex_lt_idx
            }
            requestReceiveSender(info, data => {
                if (data == true) {
                    navigation('/myPage');
                }
                console.log(data);
            })
        }
    }

    useEffect(() => {
        areaRef.current.focus();
        if (content !== '') {
            setAreaValue(content);
        }
    }, [])

    useEffect(() => {
        const { scrollHeight, clientHeight, value } = areaRef.current;
        const textareaHeight = 36;                           //한줄의 텍스트가 입력될 수 있는 Height값 (fontSize+line-Height)
        const rowValue = scrollHeight / textareaHeight       //클라이언트의 길이를 늘리기 위해 스크롤길이가 현재Height을 초과한 경우를 고려한 수
        const scrollToBottomHeight = document.documentElement.clientHeight / 2;
        var element = [];

        for(let i = 0; i < rowValue; i++) {
            element.push(<Letter_Line top={i + 1}/>)
        }

        setLine_element(element);
        setRows(rowValue);

        // 글이 절반이상 넘어가면 스크롤이 항상 제일 밑으로 이동
        // if (scrollHeight > scrollToBottomHeight) {
        //     window.scrollTo({
        //         top: document.documentElement.scrollHeight,
        //         behavior: 'smooth'  
        //     })
        // }
        console.log(content)
    }, [areaValue])

    
    return (
        <ThemeProvider theme={theme}>
            <Wrapper>
                <AreaBox>
                    {!content.length ? <HeaderLine>{title}</HeaderLine> : <></>}
                    {line_element}
                    <Letter_Area ref={areaRef} rows={rows} value={areaValue} onChange={(e) => setAreaValue(e.target.value)} wrap='hard'/>
                </AreaBox>
                <SendButton onClick={handleSendButton}>편지보내기</SendButton>
            </Wrapper>
        </ThemeProvider>
    )
}

export default WritePage;