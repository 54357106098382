export const AxiosHttpData = {
    // baseURL: `http://${window.location.hostname}:8428/`,
    // baseURL: '192.168.1.113:8428',
    baseURL: 'https://www.open-myday.xyz/',
    headers: {
        'Content-Type': 'application/json; charset=UTF-8'
    },
    responseType: 'json',
    responseEncoding: 'json'
};
