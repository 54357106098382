import styled, { ThemeProvider } from "styled-components";
import LetterList from "../list/Letter/LetterList";
import { useLocation } from "react-router-dom";
import theme from "../../styles/ThemeProps";

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    top: 66.719px;
    width: 100vw;
    height: auto;
    overflow-y: scroll;
    overflow-x: hidden;
    
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
`

const CategoryTheme = styled.p`
    margin: 32px 180px 16px;

    @media ${({ theme }) => theme.device.computer } {
        
        align-self: flex-end;
    }
    
    @media ${({ theme }) => theme.device.laptop } {
        
        align-self: flex-end;
    }

    @media ${({ theme }) => theme.device.tablet } {
        
        align-self: flex-end;
    }

    @media ${({ theme }) => theme.device.mobile_big } {
        margin: 16px 0 0 0;
        align-self: center;
    }

    @media ${({ theme }) => theme.device.mobile_small } {
        margin: 16px 0 0 0;
        align-self: center;
    }
    
    position: relative;
    padding-bottom: 4px;
    border-bottom: solid 8px #e9b599;
    
    font-family: GyeonggiBatang;
    font-size: 16px;
    font-weight: 16px;
`

const CategoryLetterPage = (props) => {
    const { state } = useLocation();
    return (
        <ThemeProvider theme={theme}>
            <Wrapper>
                <CategoryTheme>카테고리: {state.title}</CategoryTheme>
                <LetterList receive_sorted={true} category_sorted={state.category} return_sorted={false} letterSetCount={state.letterSetCount} /> 
            </Wrapper>
        </ThemeProvider>
    )
}

export default CategoryLetterPage;