import { useEffect, useRef, useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import banner from "../../resource/home-resource/ltproject-banner.jpg";
import bgImage1 from "../../resource/home-resource/ltproject1.jpg";
import bgImage2 from "../../resource/home-resource/ltproject2.jpg";
import bgImage3 from "../../resource/home-resource/ltproject3.jpg";
import bgImage4 from "../../resource/home-resource/ltproject4.jpg";
import bgImage5 from "../../resource/home-resource/ltproject5.jpg";
import bgImage6 from "../../resource/home-resource/ltproject6.jpg";
import bgImage7 from "../../resource/home-resource/ltproject7.jpg";
import theme from "../../styles/ThemeProps";

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 66.719px;
    width: 100vw;
    height: auto;
    overflow-y: scroll;
    overflow-x: hidden;
    
    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
`

const Banner = styled.img`
    width: 100%;

    position: relative;
    object-fit: cover;
`

const IntroduceProject = styled.img`
    width: calc(100%);

    @media ${({ theme }) => theme.device.computer } {
        width: calc(100% - 186px);
        margin: 0px 93px;
    }
    
    @media ${({ theme }) => theme.device.laptop } {
        width: calc(100%);
    }

    @media ${({ theme }) => theme.device.tablet } {
        width: calc(100%);
        /* height: ${() => `calc(${window.innerWidth}px * 1.2)`}; */
    }

    @media ${({ theme }) => theme.device.mobile_big } {
        width: calc(100%);
        /* height: ${() => `calc(${window.innerWidth}px)`}; */
    }

    @media ${({ theme }) => theme.device.mobile_small } {
        width: calc(100%);
        /* height: ${() => `calc(${window.innerWidth}px)`}; */
    }

    position: relative;
    
    object-fit: contain;
`

const HomePage = (props) => {
    const [bannerHeight, setBannerHeight] = useState(calcBannerHeight);
    const [imgHeight, setImgHeight] = useState(calcHeight)

    const calcHeight = () => {
        const width = window.innerWidth;
        const ratioheight = width * 1.4

        return ratioheight;
    }

    const calcBannerHeight = () => {
        const width = window.innerWidth;
        const ratioHeight = width * 9/16 * 0.605

        return ratioHeight;
    }

    const handleResize = (e) => {
        setImgHeight(calcHeight);
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        
        //컴포넌트가 마운트 시 이벤트 리스너 제거
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <Wrapper>
                <Banner height={bannerHeight} src={banner} />
                <IntroduceProject height={imgHeight} src={bgImage1}/>
                <IntroduceProject height={imgHeight} src={bgImage2}/>
                <IntroduceProject height={imgHeight} src={bgImage3}/>
                <IntroduceProject height={imgHeight} src={bgImage4}/>
                <IntroduceProject height={imgHeight} src={bgImage5}/>
                <IntroduceProject height={imgHeight} src={bgImage6}/>
                <IntroduceProject height={imgHeight} src={bgImage7}/>
            </Wrapper>
        </ThemeProvider>
        
    )
}

export default HomePage;