import styled, { keyframes } from "styled-components";


const spinAnimation = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`

const Spinner = styled.div`
    border: 4px solid skyblue;
    border-top: 4px solid transparent;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: ${spinAnimation} 1s linear infinite; /* 스피너 애니메이션 */ 
`

/**
 * SPINER UI
 * @returns 스피너를 반환합니다.
 */
const SpinBox = () => {
    return (
        <>
            <Spinner/>  
        </>
    )
}

export default SpinBox;