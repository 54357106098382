import React, { useRef, useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AxiosHttpData } from "../../Controller/Axios.config";
import logoblue from "../../resource/logo-blue.png";
import CheckBox from "../ui/CheckBox";
import ConcernList from "../list/ConcernList";
import { requestSignUp } from "../../Controller/Controller";
import SpinBox from "../ui/Spinner";
import theme from "../../styles/ThemeProps";

var email, nick, password, rePassword, authCode = '';

var concernValue = [];
var personalValue = [];

const Wrapper = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
`
const ElementBox = styled.div`
    position:relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > * {
        margin-bottom: 8px;
    }
`

const Logo = styled.img`
    position: relative;
    object-fit: cover;
    width: 250px;
    height: 250px;
`

const EmailInput = styled.input`
    position: relative;
    width: 250px;
    height: 36px;
    border: 1px solid black;
    border-radius: 8px;

    ::placeholder {
        color: gray;
    }
`
const PasswordInput = styled(EmailInput)``

const RepasswordInput = styled(EmailInput)``

const AuthcodeInput = styled(EmailInput)``

const NicknameInput = styled(EmailInput)``

const FalseMessage = styled.span`
    position: relative;
    width: 250px;
    height: 20px; 
    font-family: var(--fnt);
    color: dodgerblue;
    text-align: center;
`

const LoginButton = styled.button`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 256px;
    height: 36px;
    padding: 1px 2px;
    border: 1px solid skyblue;
    border-radius: 8px;
    color: skyblue;
    text-align: center;
    font-family: var(--fnt);
`;

const choiceLayout = (changeMessage) => {
    const handleConcernButton = (e) => {
        changeMessage('')
        if (e.target.name == 'false') {
            // 선택확인
            if (concernValue.length < 2) {
                // 선택한 수가 2개보다 적을 때
                concernValue.push(e.target.value);    
                e.target.name = 'true';
                e.target.style.backgroundColor = "skyblue";
            } else {
                // 선택한 수가 2개보다 많을 때
                changeMessage('관심사/고민은 2개까지 선택가능합니다')
            }
            
        } else {
            // 선택취소
            const copyConcernValue = concernValue;
            concernValue = copyConcernValue.filter((item) => item !== e.target.value);
            
            console.log('삭제된 데이터', concernValue);
            e.target.name = 'false';
            e.target.style.backgroundColor = "transparent";
        }
    }

    const handlePersonalButton = (e) => {
        changeMessage('')
        if (e.target.name == 'false') {
            // 선택확인
            if (personalValue.length < 2) {
                // 선택한 수가 2개보다 적을 때
                personalValue.push(e.target.value);    
                e.target.name = 'true';
                e.target.style.backgroundColor = "skyblue";
            } else {
                // 선택한 수가 2개보다 많을 때
                changeMessage('나의 성격은 2개까지 선택가능합니다')
            }
            
        } else {
            // 선택취소
            const copyPersonalValue = personalValue;
            personalValue = copyPersonalValue.filter((item) => item !== e.target.value);
            e.target.name = 'false';
            e.target.style.backgroundColor = "transparent";
        }
    }

    return (<ConcernList handleConcern={handleConcernButton} handlePersonal={handlePersonalButton}/>)
}

const SignPage = (props) => {
    const [privarcy, setPrivarcy] = useState(false);
    const [service, setService] = useState(false);
    const [choiceConcern, setChoiceConcern] = useState(false);
    const [inCodeBar, setInCodeBar] = useState([]);
    const [message, setMessage] = useState(''); 
    const [loading, setLoading] = useState('');
    const navigation = useNavigate();
    
    const handleChangeEmail = (e) => {
        email = e.target.value;
    }

    const handleChangePassword = (e) => {
        password = e.target.value;
    }

    const handleRechangePassword = (e) => {
        rePassword = e.target.value;
    }

    const handleChangeNcik = (e) => {
        nick = e.target.value;
    }

    const handleAuthCode = (e) =>{
        authCode = e.target.value;
    }
    
    const handlePrivacy = (e) => {
        setPrivarcy(!privarcy);
    }

    const handleService = (e) => {
        setService(!service);
    }

    const changeMessage = (inputMessage) => {
        setMessage(inputMessage);
    }

    const handleSign = (e) => {
        e.preventDefault();
        const userInfo = { 'email': email, 'nick': nick, 'password': password};
        const signinInfo = { 'email': email, 'nick': nick, 'password': password, 'authToken': authCode }
        const signCodeRequest = axios.create(AxiosHttpData);

        if(!choiceConcern) {
            if (authCode === '') {
                if (!email) {
                    setMessage('이메일 형식을 확인해주세요')
                } else if (!email.includes('@')) {
                    setMessage('이메일 형식을 확인해주세요');
                } else if(password.length == 0) {
                    setMessage('비밀번호를 입력해주세요')
                } else if(password !== rePassword) {
                    setMessage('비밀번호가 일치하지 않습니다 다시 입력해주세요')
                } else {
                    setMessage('');
                    if (privarcy && service) {
                        if (!loading) {
                            setLoading(true);

                            signCodeRequest.post('/api/duplicateUserInfo', userInfo).then((req) => {
                                if (req.data.result) {
                                    signCodeRequest.post('/api/sendauthCode', userInfo).then((req) => {
                                        console.log(req.data)
                                        if (req.data) {
                                            /**
                                             * 인증코드가 발송되었음을 알리고
                                             * 인즈코드창을 띄웁니다.
                                             */
                                            setInCodeBar([<AuthcodeInput placeholder="이메일로 발송된 인증번호를 입력해주세요" type='text' onChange={handleAuthCode}/>])
                                        } else {
                                            setMessage('이미 가입된 이메일입니다')
                                        }
                                        setLoading(false);
                                    });
                                } else {
                                    setMessage(req.data.errMessage)
                                    setLoading(false);
                                }
                            })    
                        }
                    } else {
                        setMessage('약관에 동의한 후에 서비스 이용이 가능합니다')
                    }
                }
            } else {
                setMessage('');
                signCodeRequest.post('/api/authCheck', signinInfo).then((req) => {
                    if (!req.data.result) {
                        setMessage(req.data.errMessage)
                    } else {
                        setChoiceConcern(true);
                    }
                })
            }
        } else {
            if (concernValue.length == 0 && personalValue == 0) {
                setMessage('관심사.고민.성격 중 한개이상 선택해야합니다')
            } else {
                concernValue = concernValue.sort();
                personalValue = personalValue.sort();
                var categoryArr = concernValue;
                personalValue.forEach(item => {
                    categoryArr.push(item)
                })
                const category = categoryArr.join(','); 
                const categoryJSON = { 'email': email, 'nick': nick, 'password': password, 'authToken': authCode, 'category': category }
                console.log(category);
                requestSignUp(categoryJSON, data => {
                    if (!data.result) {
                        setMessage(data.errMessage);
                    } else {
                        setMessage(data.message);
                        setTimeout(()=> {
                            navigation('/');
                        }, 3000)
                    }
                })
            }
        }
    }

    return ( 
        <ThemeProvider theme={theme}>
            <Wrapper>
                <ElementBox>
                    {!choiceConcern ? (
                            <>
                                <Logo src={logoblue}/>
                                <EmailInput placeholder="이메일을 입력해주세요" type='email' onChange={handleChangeEmail}/>
                                <NicknameInput placeholder="닉네임을 입력해주세요" type='text' onChange={handleChangeNcik}></NicknameInput>
                                <PasswordInput placeholder="비밀번호를 입력해주세요" type='password' onChange={handleChangePassword}/>
                                <RepasswordInput placeholder="비밀번호를 한번더 입력해주세요" type='password' onChange={handleRechangePassword}/>
                                {inCodeBar}
                                <CheckBox checked={privarcy} text="개인정보 처리방침에 동의합니다." changeHandle={handlePrivacy}/>
                                <CheckBox checked={service} text="서비스 이용약관에 동의합니다." changeHandle={handleService}/>
                            </>
                        ) : (
                            <>
                                {choiceLayout(changeMessage)}
                            </>
                        )
                    }
                    <LoginButton onClick={handleSign}>{loading? <SpinBox/> : '회원가입' }</LoginButton>
                    <FalseMessage>{message}</FalseMessage>  
                </ElementBox>
            </Wrapper>
        </ThemeProvider>   
    )
}

export default SignPage;