import { useState, useEffect, useRef } from "react";
import styled, { ThemeProvider, keyframes } from "styled-components";
import autoTextarea from "react-textarea-autosize"
import { useLocation, useNavigate } from "react-router-dom";
import theme from "../../styles/ThemeProps";

var beforeTextareaHeight = 36;

const Wrapper = styled.div`
    width: 50vw;
    margin: 0px 25vw;
    height: auto;
    
    @media ${( {theme} ) => theme.device.mobile_big } {
        width: 100vw;
        margin: 0px;
    }

    @media ${( {theme} ) => theme.device.mobile_small } {
        width: 100vw;
        margin: 0px;
    }  

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    overflow-y: scroll;
    overflow-x: hidden;

    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
`

const HeaderLine = styled.div`
    position: relative;
    width: 100%;
    height: 96px;
    align-items: center;
    justify-content: center;

    background-color: white;
`

const AreaBox = styled.div`
    width: 95%;
    min-height: 1080px;
    @media ${( {theme} ) => theme.device.mobile_big } {
        width: 100%;
    }

    @media ${( {theme} ) => theme.device.mobile_small } {
        width: 100%;
    }  

    position: relative;
    display: flex;

    justify-content: flex-start;
    background-color: #FCF5E6;
`

const Letter_Area = styled(autoTextarea)`
    position: relative;
    width: 84%;
    height: ${props => `calc(${props.rows} * 36px)`};
    margin: calc(1080px * 0.1) 8% calc(1080px * 0.1) 8%;
    padding: 0;
    font-size: 16px;
    line-height: 36px;
    overflow: hidden;

    border: none; 
    resize: none;
    background-color: #FCF5E6;

    outline: none;

    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
`

const lineDrow = keyframes`
    from {
        width: 0px;
    }

    to {
        width: 95%;
    }
`

const Letter_Line = styled.div`
    position: absolute;
    height: 1px;
    margin-top: calc(1080px * 0.1);
    margin-left: 2.5%;
    margin-right: 2.5%;
    top: ${(props) => (`calc((36px * ${props.top}))`)};

    background-color: gray;
    animation: ${() => lineDrow} 0.5s ease-out forwards;
    transition: all 0.5s ease-out forwards;

    z-index: 10;
`

const SendButton = styled.button`
    width: 95%;
    @media ${( {theme} ) => theme.device.mobile_big } {
        width: 100%;
    }

    @media ${( {theme} ) => theme.device.mobile_small } {
        width: 100%;
    }  
    position: relative;
    height: 48px;

    font-size: 16px;
    font-weight: bold;
    background-color: skyblue;
    color: white; 
`

const ReadPage = (props) => {
    // textArea onChange에 resizeTextaarea이벤트를 등록한후 useEffect로 value값에 content를 넣어주면 자동으로 줄이 생성될 것이다.
    const { mb_idx, mb_nick, lt_idx, content, ex_lt_idx } = useLocation().state;
    const [rows, setRows] = useState(1);
    const [areaValue, setAreaValue] = useState('');
    const [line_element, setLine_element] = useState([<Letter_Line top={1}/>]);
    const areaRef = useRef();
    const navigation = useNavigate();
     
    useEffect(() => {
        if (content !== '') {
            setAreaValue(content);
        }
    }, [])

    useEffect(() => {
        const { scrollHeight, clientHeight, value } = areaRef.current;
        const textareaHeight = 36;                           //한줄의 텍스트가 입력될 수 있는 Height값 (fontSize+line-Height)
        const rowValue = scrollHeight / textareaHeight       //클라이언트의 길이를 늘리기 위해 스크롤길이가 현재Height을 초과한 경우를 고려한 수
        const scrollToBottomHeight = document.documentElement.clientHeight / 2;
        var element = [];

        for(let i = 0; i < rowValue; i++) {
            element.push(<Letter_Line top={i + 1}/>)
        }

        setLine_element(element);
        setRows(rowValue);

        // 글이 절반이상 넘어가면 스크롤이 항상 제일 밑으로 이동
        if (scrollHeight > scrollToBottomHeight) {
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'smooth'  
            })
        }
    }, [areaValue])

    const handleSendButton = (e) => {
        
        // 내가 쓴 답장에 대한 답장 제어
        if (!mb_idx || !lt_idx) {
            alert('내가 쓴 답장에 대해서는 편지를 보낼 수 없습니다.');
            return;
        }

        // category를 입력하지 않기 때문에 답장을 보내는 페이지로 이동한다는 것을 할 수 있음
        const info = { mb_idx: mb_idx, mb_nick: mb_nick, lt_idx: lt_idx, content: '', receive_sender: true, ex_lt_idx: ex_lt_idx };
        navigation('/write', { state: info })
    }
    console.log('시작은되나?')

    return (
        <ThemeProvider theme={theme}>
            <Wrapper>
                <AreaBox>
                    {line_element}
                    <Letter_Area ref={areaRef} rows={rows} value={areaValue} onChange={(e) => setAreaValue(e.target.value)} wrap='hard' readOnly/>
                </AreaBox>
                <SendButton onClick={handleSendButton}>편지보내기</SendButton>
            </Wrapper>
        </ThemeProvider>
    )
}

export default ReadPage;