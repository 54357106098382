import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import LetterBox from "./LetterBox";
import { request_other_user_send_LetterInfo } from "../../../Controller/Controller";
import moment from "moment";

const Wrapper = styled.div`
    width: calc(100% - 180px);
    height: 100%; 
    padding: 0px 90px;

    @media ${({ theme }) => theme.device.mobile_big } {
        width: 100%;
        padding: 0 16px;
    }

    @media ${({ theme }) => theme.device.mobile_small } {
        width: 100%;
        padding: 0 16px;
    }

    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
`;

const SortButton = styled.button`
    position: absolute;
    top: 15px;
    right: 15px;
    width: 90px;
    height: 30px;

    @media ${({ theme }) => theme.device.mobile_big } {
        right: 36px;
    }

    @media ${({ theme }) => theme.device.mobile_small } {
        right: 36px;
    }


    border-radius: 10px;
    font-size: 16px;

    background-color: #96DCF0;
    color: white;
`

const createdate_sort = (data) => {
    // 날짜 순서대로 정렬합니다
    data.sort((a, b) => {
        const dateA = new moment(a.created_date_time)
        const dateB = new moment(b.created_date_time)

        console.log(dateA, dateB)
        return dateA < dateB ? 1 : -1; 
    })

    return data
}

const RcLetterList = (props) => {
    const [info, setInfo] = useState([]);
    const [create_date_sort, setCreate_date_sort] = useState(false);
    const buttonRef = useRef();

    const sort = (data) => {
        // *props값에 따라 데이터를 정렬합니다.
        var sorted_data = data;
        var value = []

        if (create_date_sort) {
            sorted_data = createdate_sort(data);
        }

        sorted_data.forEach((element, index) => {
            value.push(<LetterBox key={index} content={element.content} lt_idx={element.lt_idx} return_sorted={true}/>)
        });
        setInfo(value);
    }

    useEffect(() => {
        request_other_user_send_LetterInfo((data) => {
            console.log(data);
            sort(data);
        })
    }, [create_date_sort])

    const handleSortButton = (e) => {
        const text = buttonRef.current.innerText

        if (text === '답장순') {
            buttonRef.current.innerText = '최신순'
            setCreate_date_sort(true)
        } else if (text === '최신순') {
            buttonRef.current.innerText = '답장순'
            setCreate_date_sort(false)
        }
    } 

    return (
        <Wrapper>
            <SortButton ref={buttonRef} onClick={handleSortButton}>답장순</SortButton>
            {info}
        </Wrapper>
    )
}

export default RcLetterList;