import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import LetterBox from "./LetterBox";
import { requestLetterInfo, requestReceiveLetterInfo, requestSendLetterInfo } from "../../../Controller/Controller";
import moment from "moment"

const Wrapper = styled.div`
    width: calc(100% - 180px);
    height: 100%; 
    padding: 0px 90px;

    @media ${({ theme }) => theme.device.mobile_big } {
        width: 100%;
        padding: 0 16px;
    }

    @media ${({ theme }) => theme.device.mobile_small } {
        width: 100%;
        padding: 0 16px;
    }

    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
`;

const SortButton = styled.button`
    position: absolute;
    top: 15px;
    right: 15px;
    width: 90px;
    height: 30px;

    @media ${({ theme }) => theme.device.mobile_big } {
        right: 36px;
    }

    @media ${({ theme }) => theme.device.mobile_small } {
        right: 36px;
    }


    border-radius: 10px;
    font-size: 16px;

    background-color: #96DCF0;
    color: white;
`

const createdate_sort = (data) => {
    var location = window;
    var mobile = (/iphone|ipad|ipod|android/i.test(navigator.userAgent.toLowerCase()));
    const ua = navigator.userAgent.toLowerCase();
    
    // 날짜 순서대로 정렬합니다
    data.sort((a, b) => {
        const dateA = new moment(a.created_date_time)
        const dateB = new moment(b.created_date_time)

        console.log(dateA, dateB)
        return dateA < dateB ? 1 : -1; 
    })

    console.log(data);
    return data
}

const receive_sort = (data) => {
    var sorted_data = []
    //날짜 순서대로 정렬합니다
    sorted_data = createdate_sort(data)
    // 받은 편지가 많은 순서대로 정렬합니다.
    sorted_data.sort((a, b) => {
        if (a.receive_lt_cnt < b.receive_lt_cnt) return 1;
        if (a.receive_lt_cnt > b.receive_lt_cnt) return -1;
    })
    return sorted_data;
}

const LetterList = (props) => {
    // * receive_sorted: 답장받은 순서대로 정렬
    // * category_sorted TYPE OF STRING
    // * create_date_sort값은 유저가 선택하는 값
    // * write_sort값은 내가 작성한 편지를 정리하는 값
    // * return_sorted값은 내가 받은 답장을 정리하는 값
    const { receive_sorted, today_sorted, category_sorted, write_sorted, return_sorted } = props;
    const { lt_idx } = props;
    // * letterSetCount == 1 : 카테고리와 완전히 일치하는 편지만 선별함
    // * letterSetCount == 2 : 카테고리중 한개라도 일치하는 편지만 선별함
    // * letterSetCount == 3 : 카테고리와 일치하지 않더라도 모든 편지를 보여주ㅁ
    const { letterSetCount } = props;
    const [create_date_sort, setCreate_date_sort] = useState(false);
    const [info, setInfo] = useState([]);
    const buttonRef = useRef();

    const sort = (data) => {
        // *props값에 따라 데이터를 정렬합니다.
        var sorted_data = data;
        var value = []

        if (create_date_sort) {
            sorted_data = createdate_sort(sorted_data)
        } else {
            if (receive_sorted) {
                sorted_data = receive_sort(sorted_data)
            }

            if (today_sorted) {
                data.sort((a, b) => a.created_date_time - b.created_date_time)

                const now = new Date()
                
                sorted_data = data.filter((item) => {
                    const date = new Date(item.created_date_time.replace('T', ' ').replace('Z', ''))
                    return now - date <= 36 * 60 * 60 * 1000;
                })
                console.log('sorted_data:', sorted_data)
            }
        }

        if (letterSetCount === 1) {
            sorted_data = sorted_data.filter(item => {
                const value = item.category;
                var result;
                if (!category_sorted) return item;  //카테고리가 없는 유저의 경우, 그냥 다 보여주도록 무조건 반환합니다.
                if (value === category_sorted) result = value
                return result
            })
            console.log(sorted_data)
        }

        if (letterSetCount === 2) {
            sorted_data = sorted_data.filter(item => {
                if (!category_sorted) return item;

                const itemSplit = item.category.split(',');
                const category_sorted_Split = category_sorted.split(',');
                var result;
                itemSplit.forEach((value2) => {
                    category_sorted_Split.forEach(element => {
                        
                        if (value2 === element) result = element
                    });
                }) 
                return result;
            })
            console.log(sorted_data)
        }

        if (letterSetCount === 3) {
            sorted_data = sorted_data.filter(item => {
                const itemSplit = item.category.split(',');
                const category_sorted_Split = category_sorted.split(',');
                var result = true;

                itemSplit.forEach((value2) => {
                    category_sorted_Split.forEach(element => {
                        
                        if (value2 === element) {
                            result = false;
                        }

                    })
                }) 
                return result
            })
            console.log(sorted_data)
        }

        sorted_data.forEach((element, index) => {

            if (write_sorted) {
                const newContent = 'From. ' + element.mb_nick + '\n' + element.content;
                value.push(<LetterBox key={index} 
                    content={newContent}
                    realContent={element.content}
                    category={element.category} 
                    receive_lt_cnt={element.receive_lt_cnt} 
                    keywords={element.keywords}
                    mb_nick={element.nick} 
                    lt_idx={element.lt_idx} 
                    mb_idx={element.mb_idx} 
                    write_sorted={write_sorted}
                    ex_lt_idx={element.ex_lt_idx}/>)

            } else if (return_sorted) {
                const newContent = 'From. ' + element.mb_nick + '\n' + element.content;
                value.push(<LetterBox key={index} 
                    content={newContent} 
                    realContent={element.content} 
                    category={element.category}
                    mb_nick={element.nick} 
                    receive_lt_cnt={element.receive_lt_cnt} 
                    root_lt_idx={element.root_lt_idx} 
                    sender_mb_idx={element.sender_mb_idx}
                    mb_idx={element.mb_idx} 
                    lt_idx={element.lt_idx}
                    receiver_mb_idx={element.receiver_mb_idx} 
                    return_sorted={return_sorted}
                    read_yn={element.read_yn}
                    ex_lt_idx={element.ex_lt_idx}/>)
            }else {
                const newContent = 'From. ' + element.mb_nick + '\n' + element.content;
                value.push(<LetterBox key={index} 
                    content={newContent} 
                    realContent={element.content} 
                    category={element.category}
                    mb_nick={element.nick} 
                    receive_lt_cnt={element.receive_lt_cnt} 
                    root_lt_idx={element.root_lt_idx} 
                    sender_mb_idx={element.sender_mb_idx}
                    mb_idx={element.mb_idx} 
                    lt_idx={element.lt_idx}
                    receiver_mb_idx={element.receiver_mb_idx} 
                    return_sorted={return_sorted}
                    ex_lt_idx={element.ex_lt_idx}/>)
            }      
        });
        setInfo(value);
    }

    useEffect(() => {
        if(write_sorted) {
            //내가 쓴 편지
            requestSendLetterInfo((data) => {
                console.log(data);
                sort(data);
            })
        } else if (return_sorted) {
            //내가 받은 편지
            const info = { lt_idx: lt_idx }
            requestReceiveLetterInfo(info, (data) => {
                console.log(data);
                sort(data);
            })
        } else {
            // 전체 편지 정보 
            requestLetterInfo((data) => {
                console.log(data)
                sort(data);
            })
        }
    }, [create_date_sort])

    const handleSortButton = (e) => {
        const text = buttonRef.current.innerText

        if (text === '답장순') {
            buttonRef.current.innerText = '최신순'
            setCreate_date_sort(true)
        } else if (text === '최신순') {
            buttonRef.current.innerText = '답장순'
            setCreate_date_sort(false)
        }
    } 

    return (
        <Wrapper>
            <SortButton ref={buttonRef} onClick={handleSortButton}>답장순</SortButton>
            {info}
        </Wrapper>
    )
}

export default LetterList;